<template>
  <div class="card-order-item" :style="cssProps">
    <div class="card-body row">

      <div
      v-if="template === 'list'"
      :class="{
        'col-12' : template === 'list',
        'col-details' : true
        }">
        <div class="col-12">
          <div class="row">
              <div class="col-12 p-0 quote-created-at">{{ this.quote.created_at}}</div>
              <div class="col-xl-9 title col-12 p-0 d-flex align-items-center mb-2">
                {{formatTitleVehicle(item.vehicle.name)}}
                <p v-if="!this.quote.has_availability" class="availability-tag ml-3">Sem disponibilidade</p>
                
              </div>
              <div class="col-xl-3 col-12 d-flex justify-content-end p-0" v-if="template === 'list' && this.quote">
                <select class="select-action border border-secondary rounded px-2 py-1" v-model="actionSelected" @change.prevent="budgetActions()">
                    <option disabled value="">O que deseja fazer?</option>
                    <option>Consultar disponibilidade</option>
                    <option>Remover pesquisa</option>
                    <option v-if="this.quote.has_availability">Reservar</option>
                  </select>
              </div>
          </div>
        </div>
        

        <p class="mb-3 type-tag">{{getTripType(item.type)}}</p>

        <div class="configurations-wrapper row ml-0" v-if="(item.vehicle.configurations.length > 0 || item.vehicle.air_conditioning || item.vehicle.bathroom)">
          <p class="font-weight-bold mr-2">Configurações:</p>

          <p class="configurations">

            <span v-if="item.vehicle.air_conditioning">
              Ar condicionado
            </span>
            <span v-if="item.vehicle.bathroom">
              Banheiro
            </span>

            <span v-for="(config, index) in item.vehicle.configurations" :key="index">
              {{config}}
            </span>
          </p>
        </div>
        <div class="row usage-item mt-2 align-items-center">
          <div :class="{
          'row-list': template === 'list',
          'col-xl-9 col-12 d-flex flex-column flex-lg-row': true,
          }">
            <div class="origin-row address col-lg-6 col-12 pl-0">
              <h5 class="trip-info-title">Origem</h5>

              <div class="trip-info-col">
                <div class="trip-info text-capitalize">
                  <b-icon-geo-alt></b-icon-geo-alt>
                  {{ originAddress }}
                </div>

                <div class="trip-info text-capitalize">
                  <b-icon-calendar3></b-icon-calendar3>
                  {{ originDate | date_result_list}}
                </div>
              </div>

              <div class="trip-info-col">
                <div class="trip-info text-capitalize">
                  <b-icon-clock></b-icon-clock>
                  {{ originTime }}
                </div>
              </div>
            </div>

            <div class="destination-row address col-lg-6 col-12 pl-0 mt-lg-0 mt-3">
              <h5 class="trip-info-title">Destino</h5>

              <div class="trip-info-col">
                <div class="trip-info text-capitalize">
                  <b-icon-geo-alt></b-icon-geo-alt>
                  {{ destinationAddress }}
                </div>

                <div v-if="destinationDate != null " class="trip-info text-capitalize">
                  <b-icon-calendar3></b-icon-calendar3>
                  {{ destinationDate | date_result_list}}
                </div>
              </div>

              <div v-if="destinationTime != null " class="trip-info-col">
                <div class="trip-info text-capitalize">
                  <b-icon-clock></b-icon-clock>
                  {{ destinationTime }}
                </div>
              </div>

            </div>
          </div>
          <div v-if="this.quote" class="col-xl-3 col-12 col-price d-flex flex-column justify-content-end">
              <div v-if="item.type == 'customtrip'">
                <div class="btn-outline-primary btn-small mb-2 btn" @click.prevent="displayItinerary()">Ver roteiro completo</div>
              </div>
              <span class="price">{{ vehicleTotal | currency }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-price" v-if="template !== 'list'">
        <span class="price">{{ vehicleTotal | currency }}</span>
      </div>
      <div class="col-12 p-0" v-if="!this.quote">
        <div class="col-12 my-4" v-if="item.vehicle.vehicle_details">
          <h4 class="mb-3">{{this.vehicleInfos.length > 1 ? 'Veículos' : 'Veículo'}}</h4>
          <div class="row mb-5">
            <div class="col-md-3 col-12 p-3 bg-white rounded mx-xl-2 border" v-for="(info , i) in this.vehicleInfos" :key="info.type">
              <div>
                <h5 class="trip-info-title">Dados do veículo - {{i + 1}}</h5>
                <div class="trip-info">
                  <p class="m-0">Veículo: {{info.type}}</p>
                </div>
                <div class="trip-info">
                  <p class="m-0">Placa: {{info.plate}}</p>
                </div>
                <div class="trip-info">
                  <p class="m-0">Número de ordem: {{info.order}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-4" v-if="item.driver.driver_details">
          <h4 class="mb-3">{{this.driverInfos.length > 1 ? 'Motoristas' : 'Motorista'}}</h4>
          <div class="row mb-5">
            <div class="col-md-3 col-12 p-3 bg-white rounded mx-xl-2 border" v-for="(driver , i) in this.driverInfos" :key="driver.name">
              <div>
                <h4 class="trip-info-title">Dados do motorista - {{i + 1}}</h4>
                <div style="width: 100%;">
                  <div style="width: 100%;">
                    <div class="trip-info">
                      <p class="m-0">Nome: {{ driver.name }}</p>
                    </div>
                    <div class="trip-info">
                      <p class="m-0">Telefone: {{ driver.phone }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.stopPointsLength()">
        <div class="col-12 row mb-3 row-stop-points">
          <div class="col-md-12 stop-col" v-if="this.stopPointsLength()">
            <h4 class="trip-info-title">Outros pontos de embarque ou desembarque</h4>
            <div class="trip-info" v-for="(stop, index) in item.trip.stopPoints" :key="'stop' + index">
                <b-icon-geo-alt></b-icon-geo-alt> Ponto {{index +1}} : {{stop.address}}
            </div>
          </div>
        </div>
      </div>

       <div class="observations-card col-md-12" v-if="item.trip.observations && template !== 'list'">
          <h5 class="trip-info-title">Observações</h5>
          <p class="mb-3">{{item.trip.observations}}</p>
        </div>

        <!-- <div class="col-12 quote-action-btns d-flex align-content-center justify-content-end" v-if="searchTitle">
        <div class="buttons col-12 d-flex" v-if="item.type == 'viagem'">
          <div class="btn btn-block btn-details" @click="doSearch">
            <span>{{searchTitle}}</span>
          </div>

          <div class="btn btn-block btn-details btn-delete" @click="removeBudget">
            <span>Remover pesquisa</span>
          </div>

          <div class="btn btn-block btn-details btn-delete" @click="print()">
            <span>Baixar orçamento</span>
          </div>
        </div>
      </div> -->
    </div>
    <div :class="[true ? 'single-itinerary col-12' : '', showItinerary ? 'active' : 'inactive']" v-if="mustShowItinerary" @click.prevent="closeItinerary()">
      <div class="content">
        <h3>Resumo do roteiro</h3>
        <itinerary-summary :key="randomKey" :data="customRouter"></itinerary-summary>
      </div>
    </div>
  </div>
</template>

<script>
import ItinerarySummary from '../modals/CustomTripRoute/ItinerarySummary.vue'
import axios from 'axios'
import VueEasyLightbox from 'vue-easy-lightbox'
import FileSaver from 'file-saver'

export default {
  name: 'TripItem',
  props: ['item', 'index', 'template', 'searchTitle', 'removeBudget', 'doSearch', 'quote', 'vehicle', 'setSelectedVehicle', 'quotePDF', 'checkout', 'order'],
  data () {
    return {
      isVisible: false,
      gallery: [],
      indexImg: 0,
      visible: false,
      img: '',
      quoted: false,
      paymentHighestDate: false,
      paymentHighestDateFormated: false,
      bank_slip_installments: [],
      emailDate: false,
      actualDate: false,
      startDate: false,
      endDate: false,
      differenceDays: 0,
      vehicleTotal: this.getTotalByType(),
      vehicleInfos: this.vehicleDetails(),
      driverInfos: this.driverDetails(),
      actionSelected: '',
      showItinerary: false,
      mustShowItinerary: this.item.type == 'customtrip' && this.item.itinerary.active,
      randomKey: Math.round(Math.random() * 1000),
      customRouter: []
    }
  },
  components: { VueEasyLightbox, ItinerarySummary },
  methods: {
    closeItinerary () {
      this.showItinerary = false
    },
    displayItinerary () {
      this.randomKey = Math.round(Math.random() * 1000)
      this.customRouter = this.item.type == 'customtrip' ? this.item.itinerary.days : false
      this.showItinerary = true
    },
    getTripType (type) {
      switch (type) {
        case 'onewaytrip':
          return 'Viagem somente ida'
        case 'roundtrip':
          return 'Viagem ida e volta'
        case 'hourtrip':
          return 'Viagem por hora'
        case 'customtrip':
          return 'Viagem com roteiro personalizado'
        default:
          return 'Viagem'
      }
    },
    formatTitleVehicle (title) {
      // return title ? title.split(' ').slice(1).join(' ') : ''
      return title
    },
    budgetActions () {
      switch (this.actionSelected) {
        case 'Consultar disponibilidade':
          this.doSearch()
          break
        case 'Remover pesquisa':
          this.removeBudget()
          break
        case 'Baixar orçamento':
          this.quotePDF()
          break
        case 'Reservar':
          this.checkout()
          break
        default:
          return false
          break
      }
      this.actionSelected = ''
    },
    vehicleDetails () {
      let vehicle = ''
      if (!this.quote) {
        vehicle = this.item.vehicle.vehicle_details != null ? this.item.vehicle.vehicle_details.value : ''
      }
      return vehicle
    },
    driverDetails () {
      let driver = ''
      if (!this.quote) {
        driver = this.item.driver.driver_details != null ? this.item.driver.driver_details.value : ''
      }
      return driver
    },
    usageVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    sumVehicleUsageTotals () {
      let totals = 0
      if (this.item.trip.useVehicle) {
        let $scope = this
        let vehicleType = $scope.usageVehicleType(this.item.vehicle.type)
        this.item.trip.useVehicle.map(function (quote) {
          let vehicleUsage = $scope.$store.state.companyDetails.vehicleUsageQuotes
          let selectedIndex = quote.hasOwnProperty('selectedIndex') ? vehicleUsage[quote.selectedIndex] : vehicleUsage[quote.selectedindex]
          totals += selectedIndex[vehicleType] * $scope.item.vehicle.factor_usage
        })
      }

      return totals
    },
    getTotalByType () {
      let total = 0
      if (this.quote) {
        total = parseFloat(this.item.total)
      } else {
        total = parseFloat(this.item.total)
      }
      return total
    },
    // setImgGalery () {
    //   if (this.item.vehicle.gallery) {
    //     this.item.vehicle.gallery.forEach(img => {
    //       this.gallery.push(img.src)
    //     })
    //   }
    // },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    },
    showMultiple (index) {
      this.img = this.gallery

      this.indexImg = index
      this.show()
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    openPassagengerList (item) {
      this.passengers = []
      this.editing_item = item
      this.list_passanger_title = item.vehicle.name
      this.passengers = item.trip.passengers ? item.trip.passengers.value : []

      this.$emit('open-modal', this.passengers)
    },
    dateFormatter: function (date, format) {
      return this.moment(date).format(format)
    },
    downloadContract (item) {
      axios({
        method: 'POST',
        responseType: 'blob',
        url: process.env.API_URL + `services/${item.id}/generate-contract`
      })
        .then((response) => {
          FileSaver.saveAs(response.data, `contrato_servico_${item.id}.pdf`)
        })
    },
    getVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'bus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro'
        case 'carro' :
          return 'car'
        default:
          return 'bus'
      }
    },
    dailyPriceByType () {
      let total = this.item.trip.daily.quote[this.getVehicleType(this.item.vehicle.type)]
      return total
    },
    tripItemPrice () {

    },
    stopPointsLength () {
      let stopPoints = false
      if (this.item.type == 'viagem') {
        if (stopPoints) {
          stopPoints = this.item.trip.stopPoints.length > 0
        }
      }
      return stopPoints
    },
    useVehicleLength () {
      let useVehicle = false
      if (this.item.type == 'viagem') {
        useVehicle = this.item.trip.useVehicle.length > 0
      }
      return useVehicle
    }
  },
  computed: {
    lastRouteIndex () {
      return this.item.itinerary.days[0].routes.length - 1
    },
    originAddress () {
      return this.item.type == 'customtrip' ? this.item.itinerary.days[0].routes[0].origin.address : this.item.trip.origin.address
    },
    originTime () {
      return this.item.type == 'customtrip' ? this.item.itinerary.days[0].routes[0].time : this.item.trip.origin.time
    },
    originDate () {
      return this.item.type == 'customtrip' ? this.item.metas.tripDates.start.date : this.item.trip.origin.date
    },
    destinationAddress () {
      return this.item.type == 'customtrip' ? this.item.itinerary.days[0].routes[this.lastRouteIndex].destination.address : this.item.trip.destination.address
    },
    destinationDate () {
      return this.item.type == 'customtrip' ? this.item.metas.tripDates.start.date : this.item.trip.destination.date
    },
    destinationTime () {
      return this.item.type == 'customtrip' ? this.item.itinerary.days[0].routes[this.lastRouteIndex].time : this.item.trip.destination.time
    },
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  beforeMount () {
    // this.setImgGalery()
    // console.log(this.vehicleTotal)
  }
}
</script>

<style lang="scss">
.quote-created-at {
  font-size: 12px;
  color: gray;
}
.availability-tag {
  background: #ea5455;
  color: white;
  margin-bottom: 0;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  width: fit-content;
}
.single-itinerary {

    display: none;
    .content {
      background: white;
      padding: 30px;
      border-radius: 10px;
      right: -10px;
      position: relative;
      padding-right: 40px;
      border: 1px solid;
      border-color: var(--default-color);
      width: 30vw;
      max-height: 80vh;
      overflow: auto;

      &::-webkit-scrollbar {
          width: 16px!important;
          height: 6px!important;
      }
      &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.184);
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }

      h3 {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        color: var(--default-color);
      }

      @media(max-width: 980px) {
      width: 100vw;
      }
    }

    &.active {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      z-index: 999999999;
      background: #00000038;
      padding: 0;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media(max-width: 980px) {
        left: 0;
      }
    }
  }

  * {
    color: black
  }

  .row.usage-item {
    padding: 20px 10px;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    margin: 30px 0 0px 0px;

    .btn-small {
      padding: 2px 30px!important;
      border-radius: 4px;
    }
    .btn-outline-primary {
      background-color: white;
      color: var(--default-color)!important;
      border-color: var(--default-color)!important;
      &:hover {
        color: white!important;
        background-color: var(--default-color)!important;
        cursor: pointer;
      }
    }

  }

  .configurations-wrapper {
    .configurations {
        display: flex;
      span {
        background: transparent;
        color: var(--default-color);
        margin-right: 5px;
        padding: 0 5px;
        margin-bottom: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-color: var(--default-color);
        border: 1px solid;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }

  .quote-action-btns {
    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 0!important;
    }
  }
  .quote-action-btns .buttons {
    gap: 10px;
    padding: 0;
    margin-top: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

  }
  .select-action{
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-weight: 500;
  }
  .quote-action-btns .btn {
      margin: 0!important;
      background-color: var(--default-color);
      span {
        color: white;
      }
  }

  .observations-card {
    p {
      padding: 20px;
      background: #e5e5e5;
      border-radius: 5px;
      color: black;
    }
  }

  .generate-contract {
    border: 1px solid
  }

  .row-list {
    .address {
      margin-right: 20px;
      margin-bottom: 0!important;
    }
  }

  .card-order-item {
    border:none!important;
    padding: 30px 15px !important;
    background: white;

    .trip-type {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;
    }

    .col-image {
      padding-top: 0;
    }

    .vehicle-name {
      font-weight: bold;
      font-size: 1.3rem;

      @media(max-width: 1024px) {
        flex-direction: column;
      }
    }

    .card-body {
      border:none!important;
      padding:0!important;
      align-items: center;
    }

    .origin-row {
      margin-bottom: 30px;
    }

    .trip-info-title {
      margin-bottom: 10px;
      font-size: 16px!important;
      font-weight: bold;
    }

    .row-trip-info {
      margin-bottom: 20px;

      &:last-child {
        border: none!important;
      }
    }

    .trip-info {
      margin-bottom: 10px;
      display: flex;
      font-size: .8rem!important;
      align-items: center;

      svg {
        margin-right: 15px;
      }

      i {
        width: 20px;
        margin-right: 10px;
      }
    }

    .col-price {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0!important;

      @media (max-width: 1024px) {
        padding-left: 0!important;
        margin-top: 20px;
      }

      @media (max-width: 600px) {
        align-items: flex-start !important;
        justify-content: flex-start !important;

      }

      .price {
        font-size: 1.3rem!important;
        color: #293c4b;
        font-weight: 700;
        text-align: right;
        @media (max-width: 980px) {
          padding-left: 15px!important;
          text-align: center;
        }
      }
    }

    .order-item-actions {
      text-align: right;
      margin-top: 60px;

      @media (max-width: 1024px) {
        padding: 15px;
      }

      .order-item-action {
        border-radius: 5px;
        font-size: 17px;
        font-weight: bold;
        padding: 8px 18px;
        text-decoration: none;
        text-transform: uppercase;

        &.send-list {

          background-color: var(--default-color);
           @media (max-width: 576px) {
             display: block;
             text-align: center;
           }

          color: #fff;
        }
      }
    }
  }

  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 5px 20px;
    max-width: 100%;
    width: 100%;

    .col-image {
      max-width: 70px;
      max-height: 50px;
      padding-right: 10px;
      padding-top: 5px;

      img {
        border-radius: 8px;
      }
    }
  }
</style>
